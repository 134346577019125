import {
  Banner,
  Box,
  Container,
  Heading,
  Helmet,
  Interfaces,
  Layout,
  Link,
  PreStyledComponents,
  ReadMoreLink,
  Row,
  Section,
  Theme,
  media,
  routesObject
} from "@life-without-barriers/react-components"
import {
  CardBannerType,
  selectBanner
} from "../../../components/child-youth-and-family/CardBanners"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import styled from "styled-components"

const { StandardButton } = PreStyledComponents
const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const ResidentialCare = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Residential care | ${siteMetadata.title}`}
        description="For young people who are temporarily unable to live with family or foster carers."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout theme={youthFull}>
        <Banner
          image={bannerImage}
          title="Residential care"
          subTitle="For young people who are temporarily unable to live with family or foster carers."
          breadcrumbs={[
            routesObject.home,
            routesObject.service,
            {
              to: "/services/child-youth-and-family",
              text: "Child, youth and family",
              title: "Return to parent page"
            }
          ]}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>About children in residential care</Heading>
              <p>
                Children and young people in residential care are generally aged
                between 12-17 and come from all cultures and corners of our
                country. While each child has a unique set of needs, almost all
                come from complex family and social situations where they may
                have suffered neglect, trauma or abuse.
              </p>
              <Heading size={3}>
                How we support children and young people in residential care
              </Heading>
              <p>
                We have adopted an evidence-based, trauma-informed model known
                as{" "}
                <Link
                  to="/our-approach/child-youth-and-family/care-creating-conditions-for-change/"
                  rooted
                >
                  CARE
                </Link>{" "}
                to guide our work with children and their families. The
                principles of CARE help us understand the impact of trauma on a
                child’s development and help us teach children skills in a
                developmentally appropriate way.
              </p>
              <Heading size={3}>About care</Heading>
              <p>
                CARE is supported by the evidence informed{" "}
                <Link
                  to="/our-approach/child-youth-and-family/tci-therapeutic-crisis-intervention/"
                  rooted
                >
                  Therapeutic Crisis Intervention
                </Link>{" "}
                (TCI) model. This model teaches staff how to help children
                handle stress in constructive ways. Children can learn
                constructive and adaptive ways to deal with frustration,
                failure, anger, rejection, hurt and depression when they see
                their carers manage crisis well.
              </p>
              <p>
                Through training and ongoing support, our staff apply these
                models to the way they look after, teach, guide and support
                children.
              </p>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>Referrals</Heading>
                <p>
                  All residential care referrals are made through the relevant
                  government department in each state and territory. You can
                  access this information by using the links below:
                </p>
                <div className="dib-ns pt3">
                  <div className="w-100 w-auto-ns dib-ns">
                    <StateLink
                      className="pr3"
                      state="ACT"
                      title="Child and Youth Protection Services"
                      url="https://www.act.gov.au/community/child-protection-and-youth-justice/decisions-about-your-child-while-theyre-in-care"
                    />
                    <StateLink
                      className="pl3 pl0-ns pr3-ns"
                      state="NSW"
                      title="New South Wales Family and Community Services"
                      url="https://www.facs.nsw.gov.au"
                    />
                  </div>
                  <div className="w-100 w-auto-ns dib-ns">
                    <StateLink
                      className="pr3"
                      state="NT"
                      title="Territory Families"
                      url="https://territoryfamilies.nt.gov.au/"
                    />
                    <StateLink
                      className="pl3 pl0-ns pr3-ns"
                      state="QLD"
                      title="Department of Child Safety, Youth and Women"
                      url="https://www.cyjma.qld.gov.au/"
                    />
                  </div>
                </div>
                <div className="dib-ns">
                  <div className="w-100 w-auto-ns dib-ns">
                    <StateLink
                      className="pr3"
                      state="SA"
                      title="South Australia Department for Child Protection"
                      url="https://www.childprotection.sa.gov.au/"
                    />
                    <StateLink
                      className="pl3 pl0-ns pr3-ns"
                      state="TAS"
                      title="Department of Health and Human Services"
                      url="https://www.decyp.tas.gov.au/safe-children/out-of-home-care-in-tasmania/out-of-home-care-system/"
                    />
                  </div>
                  <div className="w-100 w-auto-ns dib-ns">
                    <StateLink
                      className="pr3"
                      state="VIC"
                      title="Victoria Families and Children"
                      url="https://services.dhhs.vic.gov.au/child-protection"
                    />
                    <StateLink
                      className="pl3 pl0-ns pr3-ns"
                      state="WA"
                      title="Department of Communities Child Protection and Family Support"
                      url="https://www.wa.gov.au/organisation/department-of-communities/child-protection"
                    />
                  </div>
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                {selectBanner(CardBannerType.ourApproach)}
                <div className="tc pv3">
                  <ReadMoreLink
                    title="Learn more about our approach"
                    text="Explore our approach - Changing Lives, New Directions in Care"
                    href="/our-approach/child-youth-and-family/"
                    rooted
                    className="mt3"
                  />
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

interface StateLinkProps {
  url: string
  state: string
  title: string
  className?: string
}

const StateButton = styled(StandardButton)`
  &:hover,
  &:active {
    background-color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
  }

  width: 100%;

  ${media.notSmall`
    width: 6rem;
    padding-left: 0;
    padding-right: 0;
  `}
`

const StateLink = ({ url, state, title, className }: StateLinkProps) => (
  <div className={`center dib w-50 w-auto-ns ${className || ""}`}>
    <Link
      to={url}
      title={title}
      className="dib w-100 w-auto-ns mt3 no-underline tc"
      target="_blank"
      rel="noopener"
    >
      <StateButton theme={youthFull}>{state}</StateButton>
    </Link>
  </div>
)

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: { regex: "/img-resi-care-banner-new2.jpeg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`
export default ResidentialCare
